import * as React from "react";
import {IconProps, getIconSize} from "./IconInterface"

export const IconQuestionMarkCircle = ({
  className,
  size = "md"
}: IconProps) => {
  const sizeOption = getIconSize(size)
  return (
    <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} ${sizeOption}`}
  >
    <g fill="none" fillRule="evenodd">
      <g
        stroke="#1C1F2B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path d="M10 9.714a2.574 2.574 0 0 1 5 .857c0 1.2-.822 2.208-1.934 2.492-.348.089-.64.464-.64.937" />
        <path d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
      </g>
      <circle fill="#1C1F2B" cx={12} cy={17} r={1} />
    </g>
  </svg>
  )
}
