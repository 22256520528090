import * as React from "react";
import {IconProps, getIconSize} from "./IconInterface"

export const IconDocumentClock = ({
  className,
  size = "md"
}: IconProps) => {
  const sizeOption = getIconSize(size)
  return (
    <svg width={24} height={24} className={`${className} ${sizeOption}`}>
    <g
      stroke="#1C1F2B"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M7 21h10a2 2 0 0 0 2-2V9.414a1 1 0 0 0-.293-.707l-5.414-5.414A1 1 0 0 0 12.586 3H7a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2z" />
      <path d="M12 9v4l3 3" />
    </g>
  </svg>
  )
}