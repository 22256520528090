import * as React from "react";
import {IconProps, getIconSize} from "./IconInterface"

export const IconScale = ({
  className,
  size = "md"
}: IconProps) => {
  const sizeOption = getIconSize(size)
  return (
    <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} ${sizeOption}`}
  >
    <path
      d="m3 5 3 1m0 0-3 9a5.002 5.002 0 0 0 6.001 0M6 6l3 9M6 6l6-2m6 2 3-1m-3 1-3 9a5.002 5.002 0 0 0 6.001 0M18 6l3 9m-3-9-6-2m0-2v2m0 16V4m0 16H9m3 0h3"
      stroke="#262935"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  )
}
