import * as React from "react";
import {IconProps, getIconSize} from "./IconInterface"

export const IconUserExclamation = ({
  className,
  size = "md"
}: IconProps) => {
  const sizeOption = getIconSize(size)
  return (
    <svg className={`${className} ${sizeOption}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <filter id="user-issue-a" color-interpolation-filters="auto">
          <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.109804 0 0 0 0 0.121569 0 0 0 0 0.168627 0 0 0 1.000000 0"/>
        </filter>
        <filter id="user-issue-b" color-interpolation-filters="auto">
          <feColorMatrix in="SourceGraphic" values="0 0 0 0 0.109804 0 0 0 0 0.121569 0 0 0 0 0.168627 0 0 0 1.000000 0"/>
        </filter>
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g stroke-linecap="round" stroke-linejoin="round" filter="url(#user-issue-a)">
          <path stroke="#4A5568" stroke-width="2" d="M11 4C11 6.20914 9.2091 8 7 8 4.79086 8 3 6.20914 3 4 3 1.79086 4.79086 0 7 0 9.2091 0 11 1.79086 11 4zM7 11C3.13401 11 0 14.134 0 18L14 18C14 14.134 10.866 11 7 11z" transform="translate(3 3)"/>
        </g>
        <g filter="url(#user-issue-b)">
          <g transform="translate(20 3)">
            <line x1="1" x2="1" y2="4" stroke="#4A5568" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
            <circle cx="1" cy="8" r="1" fill="#D8D8D8" stroke="#979797"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
