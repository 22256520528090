import React from 'react'
import cn from 'classnames'

interface InputProps {
  label?: any
  type?: string | undefined
  value?: any
  name?: string
  id?: string
  onChange?: (e: any) => void
  placeholder?: string
  className?: string
  isInvalid?: boolean
  isInvalidText?: string
  maxLength?: number
  onBlur?: (e: any) => void
  disabled?: boolean
  isRequired?: Boolean
}

export const Input = ({
  label,
  type,
  value,
  name,
  id,
  onChange,
  placeholder = 'Placeholder Text',
  className,
  isInvalid,
  isInvalidText,
  maxLength,
  onBlur,
  disabled,
  isRequired
}: InputProps) => {
  return (
    <div>
      <label className='block text-sm text-gray-500 mb-1'>
        {label}
        {isRequired ? <span className='text-danger-dark'> *</span> : ''}
      </label>
      <div className='relative rounded-md flex flex-col'>
        <input
          type={type}
          value={value}
          name={name}
          id={id}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
          className={cn(
            `p-2.5 bg-gray-100 focus:bg-white border focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded ${className}`,
            {
              'focus:ring-danger focus:border-danger border-danger': isInvalid
            },
            { 'cursor-not-allowed bg-gray-50 text-gray-400': disabled }
          )}
          maxLength={maxLength}
          onBlur={onBlur}
        />
        {isInvalid ? (
          <div className='block text-xs text-danger-dark mt-1'>
            {isInvalidText}
          </div>
        ) : (
          ``
        )}
      </div>
    </div>
  )
}
