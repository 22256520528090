import React from 'react'

interface AvatarProps {
  className?: string
  size?: string
  src?: string
  name?: string
}

const sm = 'h-10 w-10'
const md = 'h-14 w-14'
const lg = 'h-20 w-20'

const getAvatarSize = (size: string | undefined) => {
  switch (size) {
    case 'sm':
      return `${sm}`
    case 'md':
      return `${md}`
    case 'lg':
      return `${lg}`
    default:
      return `${sm}`
  }
}

const getTextSize = (size: string | undefined) => {
  switch (size) {
    case `${sm}`:
      return 'text-base py-2'
    case `${md}`:
      return 'text-xl py-3.5'
    case `${lg}`:
      return 'text-3xl py-5'
    default:
      return 'text-base py-2'
  }
}

function getInitials(name: string) {
  const [firstName, lastName] = name.split(' ')
  return firstName && lastName
    ? `${firstName.charAt(0)}${lastName.charAt(0)}`
    : firstName.charAt(0)
}

export const Avatar = ({
  className = 'info',
  size = 'default',
  src = 'https://picsum.photos/200', // Need to handle broken images
  name = 'Avatar Image'
}: AvatarProps) => {
  const sizeOption = getAvatarSize(size)
  const textSize = getTextSize(`${sizeOption}`)
  const [showInitials, setshowInitials] = React.useState(false)
  const initials = getInitials(`${name}`)
  const handleError = () => {
    setshowInitials(true)
  }
  return (
    <div
      className={`${sizeOption} inline-block rounded-full bg-gray-200 overflow-hidden relative`}
    >
      <img
        className={`${className}`}
        src={src}
        alt={name}
        onError={() => handleError()}
      />
      {showInitials ? (
        <span
          className={`absolute bg-gray-200 text-gray-900 left-0 top-0 ${textSize} bold text-center w-full h-full`}
        >
          {initials}
        </span>
      ) : (
        ''
      )}
    </div>
  )
}
