
import * as React from 'react';
import {isBrowser, injectScript} from './heapScript'
export interface Props { 
  appId: string; 
  userId: string;
  userData: any;
};

declare const window: any;
export const Heap: React.FC<Props> = (props) => {
  const { appId, userId, userData } = props

  const [initialUserId, setInitialUserId] = React.useState(userId)
  const [initialUserData, setInitialUserData] = React.useState(userData)

  React.useEffect(() => {
      if (!isBrowser) {
        return
      }
      if (!window.heap && appId) {
        injectScript(appId)
        if (window.heap) {
          if (userId) {
            identifyUserId()
          }
          if (userData) {
            identifyUserData()
          }
        }
      }
  })

  const identifyUserId = () => {
    if(userId === initialUserId) {
      window.heap.identify(initialUserId)
    } else {
      setInitialUserId(userId)
      window.heap.identify(initialUserId)
    }
  }

  const identifyUserData = () => {
    if(userData === initialUserData) {
      window.heap.addUserProperties(initialUserData)
    } else {
      setInitialUserData(userData)
      window.heap.addUserProperties(initialUserData)
    }
  }
    
  return null;

}

