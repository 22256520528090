import React from 'react'

interface TextProps {
  children: React.ReactNode
  className?: string
  size?: string
}

const getTextSize = (size: string | undefined) => {
  switch (size) {
    case 'sm':
      return 'text-sm py-1'
    case 'md':
      return 'text-base py-2'
    case 'lg':
      return 'text-lg py-3'
    default:
      return 'text-base py-2'
  }
}

export const Text = ({ children, className, size }: TextProps) => {
  const sizeOption = getTextSize(size)
  return <div className={`${sizeOption} ${className}`}>{children}</div>
}
