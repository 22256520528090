import React from 'react'
import {
  IconInfoCircle,
  IconCheckCircle,
  IconExclamationCircle
} from '@paro.io/base-icons'

import cn from 'classnames'

interface AlertProps {
  icon?: string
  color?: string
  label?: string
  className?: string
  variant?: string
  iconCustom?: React.ReactNode
}

const getColor = (color: string | undefined) => {
  switch (color) {
    case 'default':
      return 'gray-200'
    case 'info':
      return 'info'
    case 'warning':
      return 'warning'
    case 'danger':
      return 'danger'
    case 'success':
      return 'success'
    case 'primary':
      return 'primary'
    default:
      return 'gray-100'
  }
}

const getIcon = (icon: string | undefined) => {
  switch (icon) {
    case 'info':
      return <IconInfoCircle />
    case 'warning':
      return <IconExclamationCircle />
    case 'danger':
      return <IconExclamationCircle />
    case 'success':
      return <IconCheckCircle />
    default:
      return <IconInfoCircle />
  }
}

const getVariant = (variant: string | undefined) => {
  switch (variant) {
    case 'solid':
      return ''
    case 'subtle':
      return '-lighter'
    case 'outline':
      return 'border'
    default:
      return ''
  }
}

export const Alert = ({
  icon = 'info',
  color = 'default',
  label = 'label',
  className,
  variant = 'solid',
  iconCustom
}: AlertProps) => {
  const colorOption = getColor(color)
  const iconOption = getIcon(icon)
  const variantOption = getVariant(variant)
  const inverseIconColor =
    (color === 'primary' && variant === 'solid') ||
    (color === 'primary' && variant === null)
      ? 'filter invert'
      : ''

  return (
    <div
      className={cn(
        variant === null || variant === 'solid' || variant === 'subtle'
          ? `bg-${colorOption}${variantOption} border box-border border-${colorOption}${variantOption}`
          : `border box-border border-${colorOption}`,
        `flex pt-2 pb-1.5 px-2 rounded  space-x-2 ${className}`,
        variant === 'solid' && color !== 'default'
          ? 'text-gray-100'
          : 'text-gray-900'
      )}
    >
      {iconCustom ? (
        <div
          className={cn(
            'inline-block align-middle mr-1',
            `${inverseIconColor}`
          )}
        >
          {iconCustom}
        </div>
      ) : (
        <div>{iconOption}</div>
      )}
      <div>{label}</div>
    </div>
  )
}
