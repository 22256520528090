import * as React from "react";
import {IconProps, getIconSize} from "./IconInterface"

export const IconOfficeBuilding = ({
  className,
  size = "md"
}: IconProps) => {
  const sizeOption = getIconSize(size)
  return (
    <svg
    className={`${className} ${sizeOption}`}
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 20.053V4.895C19 3.848 18.105 3 17 3H7c-1.105 0-2 .848-2 1.895v15.158m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 6.789h1M9 10.58h1m4-3.79h1m-1 3.79h1m-5 9.474v-4.737c0-.523.448-.948 1-.948h2c.552 0 1 .425 1 .948v4.737m-4 0h4"
      stroke="#1C1F2B"
      strokeWidth={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  )
}
