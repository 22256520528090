import React from 'react'
import { Logo } from '../Logo'

interface HeaderProps {
  children?: React.ReactNode
  className?: string
  title?: string
  reverseColor?: boolean
  size?: string
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const getHeaderSize = (size: string) => {
  switch (size) {
    case 'sm':
      return 'pl-4 py-2 pr-2'
    case 'md':
      return 'pl-8 py-6 pr-6'
    case 'lg':
      return 'pl-16 py-12 pr-12'
    default:
      return 'pl-4 py-2 pr-2'
  }
}

export const Header = ({
  className,
  title,
  children,
  reverseColor,
  size = 'sm'
}: HeaderProps) => {
  const sizeOption = getHeaderSize(size)
  return (
    <div
      className={classNames(
        reverseColor
          ? `${sizeOption} border-b border-blue flow-root bg-blue text-white ${className}`
          : `${sizeOption} border-b border-grey-300 flow-root bg-white border-grey-300 ${className}`
      )}
    >
      <div className='py-2 pr-4 float-left'>
        {reverseColor ? (
          <Logo reverseColor size={size} />
        ) : (
          <Logo size={size} />
        )}
      </div>
      {title && (
        <div className='font-bold text-base py-2 pl-4 float-left'>{title}</div>
      )}
      {children && <div className='float-right'>{children}</div>}
    </div>
  )
}
