import React from 'react'

interface CardProps {
  children: React.ReactNode
  className?: string
}

export const Card = ({ children, className }: CardProps) => {
  return (
    <div
      className={`bg-white rounded-tr-lg sm:rounded-t-lg lg:rounded-lg shadow-sm ${className}`}
    >
      {children}
    </div>
  )
}
