
declare const window: any;

export const isBrowser = !!(
    (typeof window !== 'undefined' &&
      window.document && window.document.createElement)
  )

export const HeapAPI = (...args: any) => {
    if (isBrowser && window.heap) {
      window.heap.apply(null, args);
    } else {
      console.warn('Heap not initialized yet');
    }
}
  
export const injectScript = (appId: string) => {
    if (!isBrowser) {
      return
    }
    
    (function (window, document, appId) {
        // @ts-ignore
        window.heap = window.heap || [], heap.load = function (e: any, t: any) {
        window.heap.appid = e, window.heap.config = t = t || {};
        var r = t.forceSSL || "https:" === document.location.protocol, a = document.createElement("script");
        a.type = "text/javascript", a.async = !0, a.src = (r ? "https:" : "http:") + "//cdn.heapanalytics.com/js/heap-" + e + ".js";
        var n = document.getElementsByTagName("script")[0];
        // @ts-ignore
        n.parentNode.insertBefore(a, n);
        for (var o = function (e: any) {
          return function () {
            // @ts-ignore
            heap.push([e].concat(Array.prototype.slice.call(arguments, 0)))
          }
          // @ts-ignore
        }, p = ["addEventProperties", "addUserProperties", "clearEventProperties", "identify", "resetIdentity", "removeEventProperty", "setEventProperties", "track", "unsetEventProperty"], c = 0; c < p.length; c++) heap[p[c]] = o(p[c])
      };
      // @ts-ignore
      heap.load(appId);
    })(window, document, appId)
  }