import React from 'react'
import cn from 'classnames'

interface TagProps {
  color?: string
  label?: string
  borderRadius?: string
  value?: string | number
  onClick?: (selectedTagValue: string | number) => void | undefined
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
  variant?: string
}

const getColor = (color: string | undefined) => {
  switch (color) {
    case 'default':
      return 'gray-200'
    case 'info':
      return 'info'
    case 'warning':
      return 'warning'
    case 'danger':
      return 'danger'
    case 'success':
      return 'success'
    case 'primary':
      return 'primary'
    default:
      return 'gray-100'
  }
}

const getBorderRadius = (radius: string | undefined) => {
  switch (radius) {
    case 'full':
      return 'rounded-full'
    case 'l':
      return 'rounded-l-full'
    case 'r':
      return 'rounded-r-full'
    default:
      return 'rounded-full'
  }
}

const getVariant = (variant: string | undefined) => {
  switch (variant) {
    case 'solid':
      return ''
    case 'subtle':
      return '-lighter'
    case 'outline':
      return 'border'
    default:
      return ''
  }
}

export const Tag = ({
  color = 'default',
  label = 'label',
  borderRadius = 'full',
  value = '',
  onClick,
  iconLeft,
  iconRight,
  variant
}: TagProps) => {
  const colorOption = getColor(color)
  const borderRadiusOption = getBorderRadius(borderRadius)
  const variantOption = getVariant(variant)
  const inverseIconColor =
    (color === 'primary' && variant === 'solid') ||
    (color === 'primary' && variant == null)
      ? 'filter invert'
      : ''

  return (
    <div
      className={cn(
        variant == null || variant === 'solid' || variant === 'subtle'
          ? `bg-${colorOption}${variantOption} border box-border border-${colorOption}${variantOption}`
          : `border box-border border-${colorOption}`,
        `text-center px-4 pb-0.5 pt-1 text-xs inline-block break-words ${borderRadiusOption}`,
        variant === 'solid' && color !== 'default'
          ? 'text-gray-100'
          : 'text-gray-900'
      )}
      onClick={() => onClick?.(value)}
    >
      {iconLeft ? (
        <div className={`inline-block align-middle mr-1 ${inverseIconColor}`}>
          {iconLeft}
        </div>
      ) : (
        ''
      )}
      <div className='inline-block'>{label}</div>
      {iconRight ? (
        <div className={`inline-block align-middle ml-1 ${inverseIconColor}`}>
          {iconRight}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

Tag.defaultProps = {
  onClick: null
}
