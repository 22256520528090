import classNames from 'classnames'
import React from 'react'
import styles from './Select.module.css'

interface TextInputProps {
  label?: string
  value?: any
  required: boolean
  isInvalid?: boolean
  isInvalidText?: string
  className?: string
  onChange?: (e: any) => void
  options: {
    label: string
    value: string
    id?: string
  }[]
  defaultValue?: any
  id?: string
  disabled?: boolean
  size?: 'md' | 'lg'
}

const getSize = (size: string | undefined) => {
  switch (size) {
    case 'md':
      return ''
    case 'lg':
      return 'h-14'
    default:
      return ''
  }
}

export const Select = ({
  label,
  options,
  required,
  isInvalid,
  isInvalidText,
  className,
  onChange,
  value,
  id,
  disabled,
  size
}: TextInputProps): JSX.Element => {
  return (
    <div className={`w-full ${className}`}>
      <label className='block text-sm text-gray-500 mb-1'>
        {label} {required ? <span className='text-danger-dark'> *</span> : ''}
      </label>
      <div className='relative rounded flex flex-col'>
        <select
          value={value}
          onChange={onChange}
          required={required}
          className={classNames({
            [styles.select]: true,
            [getSize(size)]: true,
            'base-ui-select appearance-none sm:text-sm bg-gray-100 block w-full pl-4 pr-10 py-2.5 text-base border focus:outline-none focus:ring-1 rounded':
              true,
            'cursor-not-allowed text-gray-400 disabled': disabled,
            [styles.selectDisabled]: disabled,
            'focus:ring-danger focus:border-danger border-danger': isInvalid,
            'border-gray-300 focus:ring-primary focus:border-primary':
              !isInvalid
          })}
          id={id}
          disabled={disabled}
        >
          {options.map((option) => {
            return (
              <option key={option.value} value={option.value} id={option.id}>
                {option.label}
              </option>
            )
          })}
        </select>
        {isInvalid ? (
          <div className='block text-xs text-danger-dark mt-1'>
            {isInvalidText}
          </div>
        ) : (
          ``
        )}
      </div>
    </div>
  )
}
