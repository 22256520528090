import React from 'react'

interface HeadingProps {
  children: React.ReactNode
  className?: string
  size?: string
}

const getHeadingSize = (size: string | undefined) => {
  switch (size) {
    case 'h1':
      return 'font-bold text-4xl pt-6 pb-2 lg:pt-7 lg:pb-3'
    case 'h2':
      return 'font-bold text-3xl pt-4 pb-2 lg:pt-6 lg:pb-2'
    case 'h3':
      return 'font-bold text-2xl pt-2.5 pb-1.5 lg:pt-4 lg:pb-2'
    case 'h4':
      return 'font-bold text-xl pt-2 pb-1 lg:pt-2.5 lg:pb-1.5'
    case 'h5':
      return 'font-bold text-lg pt-1.5 pb-0.5 lg:pt-2 lg:pb-1'
    case 'h6':
      return 'font-bold text-base py-1 lg:py-2 lg:pt-1.5 lg:pb-0.5'
    default:
      return 'font-bold text-3xl pt-4 pb-2 lg:pt-6 lg:pb-2'
  }
}

export const Heading = ({ children, className, size }: HeadingProps) => {
  const sizeOption = getHeadingSize(size)
  return <div className={`${sizeOption} ${className}`}>{children}</div>
}
